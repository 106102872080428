import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import s_icon1 from "../assets/images/blog/s_icon1.png"
// import heart_icon from "../assets/images/blog/heart_icon.png"
// import liked_icon from "../assets/images/blog/liked_icon.png"
// import ribbon from "../assets/images/blog/ribbon.png"
// import bookmark from "../assets/images/blog/bookmark.png"

// import fb from "../assets/images/studydetail/fb.png"
// import linkedin from "../assets/images/studydetail/linkedin.png"
// import whatsapp from "../assets/images/studydetail/whatsapp.png"
// import insta from "../assets/images/studydetail/insta.png"
// import twitter from "../assets/images/studydetail/twitter.png"
import fb_icon from "../assets/images/studydetail/fb.png"
import BounceIndicator from "react-activity/lib/Bounce"

import linkedin_icon from "../assets/images/studydetail/linkedin.png"
import whatsapp_icon from "../assets/images/studydetail/whatsapp.png"
import twitter_icon from "../assets/images/studydetail/twitter.png"
import banner from "../assets/images/studydetail/path-1179@1x.png"
import default_profile_img from "../assets/images/default-profile.png"
// import bannerImage from "../assets/images/study_detail_banner.png"
import { useQuery, useApolloClient, useMutation } from "@apollo/client"
import {
  BLOG_DETAILS_DATA,
  CHECK_FAVOURITE_ARTICLES_FOR_USER,
  CHECK_BOOKMARK_ARTICLES_FOR_USER,
  GET_ALL_FAVOURITE_ARTICLES_USER,
  GET_ALL_BOOKMARK_ARTICLES_USER,
  UPDATE_FAVOURITE_ARTICLES_USER,
  UPDATE_BOOKMARK_ARTICLES_USER,
  UPDATE_FAVOURITE_ARTICLES_DATA,
  UPDATE_BOOKMARK_ARTICLES_DATA,
  BLOG_DETAIL_URL,
} from "../components/graphql"
import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"
import moment from "moment"
import blog_banner from "../assets/images/blog/blog_banner.jpg"
import $ from "jquery"

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share"
import Login from "../components/login"

function BlogDetailPage({ location, data, pageContext }) {
  const client = useApolloClient()

  const [open, setOpen] = useState(false)
  const [login, setLogin] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [username, setUsername] = useState(undefined)
  const [userID, setUserID] = useState(undefined)
  var locationRef = location.href
  if (locationRef) {
    if (locationRef.slice(-1) === "/") {
      locationRef = locationRef.slice(0, -1)
    }
  }

  console.log("locationRef - topic", locationRef)
  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      if (typeof document !== `undefined`) {
        localStorage.setItem('lastRevistedPage',window.location.pathname)
      }
    }
  });
  var lastContent = undefined
  var strippedContent = undefined

  if (locationRef) {
    lastContent = locationRef.substring(locationRef.lastIndexOf("/") + 1)

    strippedContent = lastContent.substring(
      0,
      lastContent.indexOf("#") + "#".length - 1
    )

    if (strippedContent === "") {
      strippedContent = lastContent
    }
  }
  const slug = strippedContent
  console.log("strippedContent - topic", slug)
  var blogId = ""
  const slugContent = useQuery(BLOG_DETAIL_URL, {
    variables: { url: slug },
  })
  const [liked, setLiked] = useState(false)
  const [bookMark, setBookMark] = useState(false)
  const [updateUserFavourite] = useMutation(UPDATE_FAVOURITE_ARTICLES_USER)
  const [updateArticleFavourite] = useMutation(UPDATE_FAVOURITE_ARTICLES_DATA)
  const [updateUserBookmark] = useMutation(UPDATE_BOOKMARK_ARTICLES_USER)
  const [updateArticleBookmark] = useMutation(UPDATE_BOOKMARK_ARTICLES_DATA)
  const [phoneError, setphoneError] = useState('');
  const [successMessage,setSuccessMessage] = useState("");
  const [errorMessage,setErrorMessage] = useState("");

  React.useEffect(() => {
    console.log("useEffect called")
    setUsername(localStorage.getItem("userName"))
    setUserID(localStorage.getItem("userId"))
    if ($(window).width() > 991) {
      $(window).scroll(function () {
        var scroll = $(window).scrollTop()
        if ($(".center-column .person").length) {
          var distance = $(".center-column .person").offset().top
          // $window = $(window);
          var outerContentsHeight = $(".home-banner.study-detail").height()
          var outerContentsHeight1 = outerContentsHeight - 50
          console.log(outerContentsHeight1)
          var scroll1 = $(window).scrollTop() + 500
          if (scroll >= outerContentsHeight1) {
            $(".home.study").addClass("fixed-section")
          } else {
            $(".home.study").removeClass("fixed-section")
          }
          if (scroll1 >= distance) {
            $(".home.study").removeClass("fixed-section")
          }
        }
      })
    }
  }, [])

  if (slugContent.data) {
    if (slugContent.data.articles.length !== 0) {
      blogId = slugContent.data.articles[0].id
    }
  }
  if (!slug || slug === "null" || slug === "undefined") {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      window.location.replace("/blog")
    }
  }
  if (userID && blogId) {
    client
      .query({
        query: CHECK_FAVOURITE_ARTICLES_FOR_USER,
        variables: { id: userID, blogid: blogId },
        fetchPolicy: "network-only",
      })
      .then(res => {
        if (res.data.user) {
          if (res.data.user.favouriteArticles.length > 0) {
            setLiked(true)
          }
        }
      })
    client
      .query({
        query: CHECK_BOOKMARK_ARTICLES_FOR_USER,
        variables: { id: userID, blogid: blogId },
        fetchPolicy: "network-only",
      })
      .then(res => {
        if (res.data.user) {
          if (res.data.user.bookmarkArticles.length > 0) {
            setBookMark(true)
          }
        }
      })
  }

  const detailContent = useQuery(BLOG_DETAILS_DATA, {
    variables: { id: blogId },
  })

  const currentURL = location.href
  const bannerImg = detailContent.data
    ? detailContent.data.article.bannerImage
      ? process.env.BACKEND_URL + detailContent.data.article.bannerImage.url
      : blog_banner
    : blog_banner
  const favoriteClick = e => {
    console.log("111111111111111111111111111")

    if (localStorage.getItem("userId")) {
      client
        .query({
          query: GET_ALL_FAVOURITE_ARTICLES_USER,
          variables: { id: userID },
        })
        .then(res => {
          if (res.data.user) {
            let articleList = []
            if (res.data.user.favouriteArticles.length > 0) {
              res.data.user.favouriteArticles.map(record => {
                articleList.push(record.id)
              })
            }
            articleList.push(blogId)
            updateUserFavourite({
              variables: {
                id: userID,
                articleID: articleList,
              },
            }).then(response => {
              var count = 0
              if (detailContent.data.article.favoriteCount) {
                count = detailContent.data.article.favoriteCount
              }
              count = count + 1
              updateArticleFavourite({
                variables: {
                  id: blogId,
                  favoriteCount: count,
                },
              })
                .then(response => {
                  console.log(response)
                })
                .finally(e => {
                  setLiked(true)
                })
            })
          }
        })
    } else {
      console.log("logouttttttttttttttttttttttttt")
      e.preventDefault()
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("redirect", true)
      localStorage.setItem("lastPage","blog-detail")
      localStorage.setItem("lastPageUrl",detailContent.data?detailContent.data.article?detailContent.data.article?detailContent.data.article.url:null:null:null)
      return false
    }
  }

  const favoriteUnClick = e => {
    console.log("2222222222222222222222222222222")
    client
      .query({
        query: GET_ALL_FAVOURITE_ARTICLES_USER,
        variables: { id: userID },
      })
      .then(res => {
        if (res.data.user) {
          let articleList = []

          if (res.data.user.favouriteArticles.length > 0) {
            res.data.user.favouriteArticles.map(record => {
              if (blogId !== record.id) {
                articleList.push(record.id)
              }
            })
          }
          updateUserFavourite({
            variables: {
              id: userID,
              articleID: articleList,
            },
          }).then(response => {
            console.log("reponse", response.data)
            var count = 0
            if (detailContent.data.article.favoriteCount) {
              count = detailContent.data.article.favoriteCount
            }
            count = count - 1
            updateArticleFavourite({
              variables: {
                id: blogId,
                favoriteCount: count,
              },
            })
              .then(response => {
                console.log(response)
              })
              .finally(e => {
                setLiked(false)
              })
          })
        }
      })
  }

  const bookmarkClick = e => {
    console.log("clickeddddddddddddd")
    if (localStorage.getItem("userId")) {
      console.log("XXXXXXXXXXXXXXXXXXXXXXXXX")
      client
        .query({
          query: GET_ALL_BOOKMARK_ARTICLES_USER,
          variables: { id: userID },
        })
        .then(res => {
          if (res.data.user) {
            let articleList = []
            if (res.data.user.bookmarkArticles.length > 0) {
              res.data.user.bookmarkArticles.map(record => {
                articleList.push(record.id)
              })
            }
            articleList.push(blogId)
            updateUserBookmark({
              variables: {
                id: userID,
                articleID: articleList,
              },
            }).then(response => {
              var count = 0
              if (detailContent.data.article.wishlistCount) {
                count = detailContent.data.article.wishlistCount
              }
              count = count + 1
              updateArticleBookmark({
                variables: {
                  id: blogId,
                  wishlistCount: count,
                },
              })
                .then(response => {
                  console.log(response)
                })
                .finally(e => {
                  setBookMark(true)
                })
            })
          }
        })
    } else {
      console.log("logouttttttttttttttttttttttttt")
      e.preventDefault()
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("redirect", true)
      localStorage.setItem("lastPage","blog-detail")
      localStorage.setItem("lastPageUrl",detailContent.data?detailContent.data.article?detailContent.data.article?detailContent.data.article.url:null:null:null)
      return false
    }
  }
  const bookmarkUnClick = e => {
    console.log("unclickeddddddddddddddddddd")
    console.log("2222222222222222222222222222222")
    client
      .query({
        query: GET_ALL_BOOKMARK_ARTICLES_USER,
        variables: { id: userID },
      })
      .then(res => {
        if (res.data.user) {
          let articleList = []

          if (res.data.user.bookmarkArticles.length > 0) {
            res.data.user.bookmarkArticles.map(record => {
              if (blogId !== record.id) {
                articleList.push(record.id)
              }
            })
          }
          updateUserBookmark({
            variables: {
              id: userID,
              articleID: articleList,
            },
          }).then(response => {
            console.log("reponse", response.data)
            var count = 0
            if (detailContent.data.article.wishlistCount) {
              count = detailContent.data.article.wishlistCount
            }
            count = count - 1
            updateArticleBookmark({
              variables: {
                id: blogId,
                wishlistCount: count,
              },
            })
              .then(response => {
                console.log(response)
              })
              .finally(e => {
                setBookMark(false)
              })
          })
        }
      })
  }
  const { strapiArticle, allStrapiHomePage } = data
  console.log("strapiArticle", strapiArticle + "    =", pageContext)
  const seoTitle = strapiArticle !== null ? strapiArticle.title : ""
  const seoMetaDescription =
    strapiArticle !== null ? strapiArticle.metaDescription : ""
  // const seoImage = detailContent.data
  //   ? detailContent.data.article.bannerImage
  //     ? detailContent.data.article.bannerImage.url
  //     : ""
  //   : ""
  const seoImage =
    strapiArticle !== null
      ? strapiArticle.image !== null
        ? strapiArticle.image.length !== 0
          ? strapiArticle.image[0].url !== null
            ? strapiArticle.image[0].url
            : ""
          : ""
        : ""
      : ""

  console.log(
    "strapiArticle--------strapiArticle ------------------",
    // strapiArticle.image
    seoImage
  )

  // allStrapiHomePage !== null
  //   ? allStrapiHomePage.edges[0].node.metaImage
  //     ? allStrapiHomePage.edges[0].node.metaImage.url
  //     : ""
  //   : ""
  if (slugContent.loading)
    return (
      <>
        <SEO
          title={seoTitle}
          description={seoMetaDescription}
          image={seoImage}
        />
        <div className={"centered"}>
          <BounceIndicator size={25} color={"#0055a4"} />
        </div>
      </>
    )
  if (detailContent.loading)
    return (
      <>
        <SEO
          title={seoTitle}
          description={seoMetaDescription}
          image={seoImage}
        />
        <div className={"centered"}>
          <BounceIndicator size={25} color={"#0055a4"} />
        </div>
      </>
    )
  return (
    <>
      <SEO title={seoTitle} description={seoMetaDescription} image={seoImage} />
      <Layout setTitle="Blog Detail" tag="blog">
        <Login
          open={open}
          setOpen={setOpen}
          login={login}
          setLogin={setLogin}
          setUsername={setUsername}
          setUserID={setUserID}
          forgotPassword={forgotPassword}
          setForgotPassword={setForgotPassword}
          errorMessage ={errorMessage}
          setErrorMessage ={setErrorMessage}
          successMessage ={successMessage}
          setSuccessMessage ={setSuccessMessage}
          phoneError ={phoneError}
          setphoneError ={setphoneError}
        />
        {/* <!-- Banner --> */}
        {detailContent.data ? (
          <>
            <section>
              <div
                className="home-banner study-detail blog-detail"
                style={{
                  backgroundImage: `url(${bannerImg})`,
                  backgroundAttachment: "fixed",
                }}
              >
                {/* <div className="banner-image"></div> */}
                <div className="banner-overlay">
                  <div className="smalltext">
                    <img src={banner} alt="" />
                    <Link to="/blog/">/ Blog</Link>
                  </div>
                  <div className="text">
                    {detailContent.data ? detailContent.data.article.title : ""}
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- /banner --> */}
            {/* <!-- main --> */}
            <main className="home study">
              <div className="main-content">
                <div className="container">
                  {/* <!---- 3column ----> */}
                  <div className="layouts">
                    <div className="left-column">
                      <div className="toggle-link text-left">
                        {detailContent.data.article.author ? (
                          <div className="s-icon">
                            <div className="image">
                              <img
                                src={
                                  detailContent.data.article.author.profileImage
                                    ? process.env.BACKEND_URL +
                                      detailContent.data.article.author
                                        .profileImage.url
                                    : default_profile_img
                                }
                                alt=""
                              />
                            </div>
                            <div className="right">
                              <div className="name">
                                {detailContent.data.article.author.firstName}{" "}
                                {detailContent.data.article.author.lastName}
                              </div>
                              <div className="date">
                                {detailContent.data.article.blogDate ? (
                                  <>
                                    {moment(
                                      detailContent.data.article.blogDate
                                    ).format("MMMM DD")}
                                  </>
                                ) : (
                                  ""
                                )}{" "}
                                |{" "}
                                {detailContent.data.article.ReadingTimeInMinutes
                                  ? detailContent.data.article
                                      .ReadingTimeInMinutes
                                  : 0}{" "}
                                Min Read
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="heart-ribbon">
                          <div className="image heart">
                            {liked ? (
                              <i
                                class="fas fa-heart"
                                style={{
                                  color: "#ff0000",
                                }}
                                onClick={favoriteUnClick}
                              ></i>
                            ) : (
                              <i
                                class="far fa-heart"
                                onClick={favoriteClick}
                              ></i>
                            )}
                            {detailContent.data.article.favoriteCount
                              ? detailContent.data.article.favoriteCount
                              : 0}
                          </div>
                          <div className="image ribbon">
                            {bookMark ? (
                              <i
                                class="fas fa-bookmark"
                                onClick={bookmarkUnClick}
                              ></i>
                            ) : (
                              <i
                                class="far fa-bookmark"
                                onClick={bookmarkClick}
                              ></i>
                            )}
                            {detailContent.data.article.wishlistCount
                              ? detailContent.data.article.wishlistCount
                              : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="center-column">
                      <section className="one">
                        <p>
                          {detailContent.data ? (
                            <>
                              <ReactMarkdown
                                source={detailContent.data.article.description}
                                transformImageUri={uri =>
                                  uri.startsWith("http")
                                    ? uri
                                    : `${process.env.BACKEND_URL}${uri}`
                                }
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </p>
                      </section>
                   {/* {detailContent.data ? (
                        detailContent.data.article.sectionContent.length !==
                        0 ? (
                          <section className="two pb50">
                            <div className="article-list">
                              <h3>
                                <strong>
                                  {detailContent
                                    ?  detailContent.data.article.title
                                    : ""}
                                </strong>
                              </h3>
                              {detailContent.data.article.sectionContent.map(
                                (sectionContent, index) => (
                                  <a key={index} href={`#section${index}`}>
                                    {`${
                                      index < 10 ? "0" + (index + 1) : index
                                    }. ` +
                                      sectionContent.title +
                                      " "}
                                  </a>
                                )
                              )}
                            </div>
                          </section>
                        ) : null
                      ) : null} */}
                      <section className="three">
                        {detailContent.data.article.sectionContent.map(
                          (sectionContent, index) => (
                            <div className="article-overview pb100" key={index} id={`section${index}`}>
                              <div className="number">
                                {`${index < 10 ? "0" + (index + 1) : index}`}
                              </div>
                              <div className="title">
                                {sectionContent.title}
                              </div>
                              <div className="desc">
                                <ReactMarkdown
                                  source={sectionContent.description}
                                  transformImageUri={uri =>
                                    uri.startsWith("http")
                                      ? uri
                                      : `${process.env.BACKEND_URL}${uri}`
                                  }
                                />
                              </div>
                              {/* <div className="s_text">
                                                        One of the final steps towards realising your dream of studying in France is finding student 
                                                        accommodation. It is no exaggeration to say that finding accommodation in France is the most difficult 
                                                        step of your journey, even Campus France mentions the same. So to make this a seamless process for you, 
                                                        in this article we tell you everything you need to know to book your accommodation in France.
                                                    </div> */}
                            </div>
                          )
                        )}
                      </section>

                      <section className="person">
                        {detailContent.data.article.author ? (
                          <>
                            <div className="p-icon">
                              <div className="image">
                                <img
                                  src={
                                    detailContent.data.article.author
                                      .profileImage
                                      ? process.env.BACKEND_URL +
                                        detailContent.data.article.author
                                          .profileImage.url
                                      : default_profile_img
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="right">
                                <div className="name">
                                  {detailContent.data.article.author.firstName}{" "}
                                  {detailContent.data.article.author.lastName}
                                </div>
                                <div className="date">
                                  {detailContent.data.article.author.userRole
                                    ? detailContent.data.article.author.userRole
                                        .name
                                    : ""}
                                </div>
                              </div>
                            </div>
                            {detailContent.data.article.author.biography ? (
                              <div className="quote">
                                <span className="left">"</span>
                                <ReactMarkdown
                                  source={
                                    detailContent.data.article.author.biography
                                  }
                                  transformImageUri={uri =>
                                    uri.startsWith("http")
                                      ? uri
                                      : `${process.env.BACKEND_URL}${uri}`
                                  }
                                />
                                <span className="right">"</span>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </section>
                    </div>
                    {/* <div className="right-column">
                                    <div className="spread-the-love">
                                        Spread the love with someone.
                                    </div>
                                    <div className="social-img">
                                        <img src={fb} alt="facebook" />
                                    </div>
                                    <div className="social-img">
                                        <img src={linkedin} alt="linkedin" />
                                    </div>
                                    <div className="social-img">
                                        <img src={whatsapp} alt="whatsapp" />
                                    </div>
                                    <div className="social-img">
                                        <img src={insta} alt="instagram" />
                                    </div>
                                    <div className="social-img">
                                        <img src={twitter} alt="twitter" />
                                    </div>
                                </div> */}
                    <div className="right-column">
                      <div className="spread-the-love">
                        <span className="share-small-text">
                          SHARE
                          <br />
                        </span>
                        <span className="grey-color-text">
                          Spread the love with someone.
                        </span>
                      </div>
                      <div className="social-img">
                        <FacebookShareButton
                          quote={seoMetaDescription || seoTitle}
                          url={currentURL}
                          hashtag="#dimensionsFrance"
                        >
                          <img src={fb_icon} alt="facebook" />
                        </FacebookShareButton>
                      </div>
                      <div className="social-img">
                        <LinkedinShareButton
                          title={"Dimensions France"}
                          summary={seoMetaDescription || seoTitle}
                          url={currentURL}
                          source={currentURL}
                          hashtag="#dimensionsFrance"
                        >
                          <img src={linkedin_icon} alt="linkedin" />
                        </LinkedinShareButton>
                      </div>
                      <div className="social-img">
                        <WhatsappShareButton
                          title={seoMetaDescription || seoTitle}
                          url={currentURL}
                          hashtag="#dimensionsFrance"
                        >
                          <img src={whatsapp_icon} alt="whatsapp" />
                        </WhatsappShareButton>
                      </div>
                      <div className="social-img">
                        <TwitterShareButton
                          title={seoMetaDescription || seoTitle}
                          url={currentURL}
                          hashtag="#dimensionsFrance"
                        >
                          <img src={twitter_icon} alt="twitter" />
                        </TwitterShareButton>
                      </div>
                    </div>
                  </div>
                  {/* <!---- /3column ----> */}
                </div>
                {/* <!-- /container ---> */}
              </div>
            </main>
          </>
        ) : null}
      </Layout>
    </>
  )
}
export default BlogDetailPage

export const query = graphql`
  query blogWithID($pageID: String) {
    strapiArticle(id: { eq: $pageID }) {
      title
      metaDescription
      image {
        url
      }
    }
    allStrapiHomePage {
      edges {
        node {
          metaImage {
            url
          }
        }
      }
    }
  }
`
